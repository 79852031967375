import React from 'react';
import { Container, Jumbotron, Row, Col, Button } from 'reactstrap';
import Modal from "react-responsive-modal";
import PreQForm from '../components/layout/form/preQForm';

import './landingPage/lp-downtown.scss';
import Footer from "../components/layout/footer/footer";

class ThankYou extends React.Component {
    constructor(props){
        super(props);
        this.state = {open: false};
        this.handleLoad = this.handleLoad.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    componentDidMount() {
        window.addEventListener('load', this.handleLoad);
    }

    componentWillUnmount() {
        window.addEventListener('load', this.handleLoad);
    }

    handleLoad() {
        fbq.push('track', "Lead");
    }

    onOpenModal = () => {
        this.setState({ 
          open: true
        });
      }
    
      onCloseModal = () => {
        this.setState({
          open: false
        });
      }

    render() {
        const { open } = this.state;
        const styles = {
            overlay: {
                'zIndex': '9999999',
            },
            modal: {
                'borderRadius': '5px',
                'background-color': 'rgba(255,255,255, .3)',
                //'font-size': '1rem',
                'padding': '0px',
            },
            closeButton: {
                'cursor': 'pointer',
            }
        }

        return (
            <div>
                <Modal open={ open } onClose={this.onCloseModal} styles={styles} center>
                    <PreQForm />
                </Modal>
                <Jumbotron className="jumbo">
                    <Container className="hAuto">
                        <Row className="hAuto justify-content-center">
                            <Col className="jForm align-self-center" xs="10" md="6">
                                <h2 className="text-center textBlack"><span className="greenUnderline">Thanks For Getting In Touch!</span></h2>
                                <h5 className="text-center textBlack mt-3"> 
                                    A member of our team will reach out
                                    shortly to assist you. If you would
                                    like to get pre-qualified for your new
                                    home, click the button below!
                                </h5>
                                <Row className="hAuto justify-content-center">
                                    <Col className="align-self-center">
                                        <Row className="justify-content-center">
                                            <Button className="subButton mt-3" size="lg" color="primary" onClick={this.onOpenModal}>Click Here To Get Pre-Qualified!</Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>
                <Container className="mb-3 mt-3">
                    <Row className="justify-content-center text-center">
                        <Footer/>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default ThankYou;