import React from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { navigate } from 'gatsby';

const Podio = require('podio-js').api;

// get the API id/secret
const clientId = 'local-leasing-4h21wh';
const clientSecret = process.env.PODIO_CLIENT_SECRET;

// get the app ID and Token for appAuthentication
const appId = process.env.PODIO_APP_ID;
const appToken = process.env.PODIO_APP_TOKEN;

// get the campaign ID
const campaignID = parseInt(process.env.LL_CAMPAIGN_ID);

//get item ID
//const itemId = localStorage.getItem("item-Id")

class PreQForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showForm: true, 
            showMsg: false,
            itemId: localStorage.getItem("item-Id")
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showInput = this.showInput.bind(this);
    }

    showInput = () => {
        if(document.getElementsByClassName('petYes')[0].checked){
            document.getElementById("pet-type").required = true;
            document.getElementsByClassName('pet-type')[0].style.display = 'block';
        } else {
            document.getElementById("pet-type").required = false;
            document.getElementsByClassName('pet-type')[0].style.display = 'none';
        }

        if(document.getElementsByClassName('evictionYes')[0].checked){
            document.getElementById("evictionYear").required = true;
            document.getElementsByClassName('evictionYear')[0].style.display = 'block';
        } else {
            document.getElementById("evictionYear").required = false;
            document.getElementsByClassName('evictionYear')[0].style.display = 'none';
        }

        if(document.getElementsByClassName('bankruptcyYes')[0].checked){
            document.getElementById("bankruptcyYear").required = true;
            document.getElementsByClassName('bankruptcyYear')[0].style.display = 'block';
        } else {
            document.getElementById("bankruptcyYear").required = false;
            document.getElementsByClassName('bankruptcyYear')[0].style.display = 'none';
        }
        
        if(document.getElementsByClassName('felonyYes')[0].checked){
            document.getElementById("felonyExp").required = true;
            document.getElementsByClassName('felonyExp')[0].style.display = 'block';
        } else {
            document.getElementById("felonyExp").required = false;
            document.getElementsByClassName('felonyExp')[0].style.display = 'none';
        } 
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        const json = {}

        //Add form items to json object
        json['move-in-date'] = {
            'start': `${document.getElementById('moveInDate').value} 00:00:00`,
        }

        json['how-many-occupants-will-live-in-the-unit'] = {
            'value': document.getElementById('occupants').value
        }

        json['gross-monthly-income'] = {
            'value': document.getElementById('grossIncome').value,
            'currency': 'USD',
        }

        json['if-so-how-many-type-breed-weight'] = {
            'value': document.getElementsByClassName('petYes')[0].checked ? `Yes, ${document.getElementById('pet-type').value}` : 'No'
        }

        json['ever-had-an-evictionforeclosure-if-so-why'] = {
            'value': document.getElementsByClassName('evictionYes')[0].checked ? `Yes, ${document.getElementById('evictionYear').value}` : 'No'
        }

        json['ever-had-an-bankruptcy-if-so-why'] = {
            'value': document.getElementsByClassName('bankruptcyYes')[0].checked ? `Yes, ${document.getElementById('bankruptcyYear').value}` : 'No'
        }

        json['have-you-ever-been-convictedcharged-with-a-crime-if-so-'] = {
            'value': document.getElementsByClassName('felonyYes')[0].checked ? `Yes, ${document.getElementById('felonyExp').value}` : 'No'
        }
        
        // instantiate the SDK
        const podio = await new Podio({
            authType: 'app',
            clientId: clientId,
            clientSecret: clientSecret
        });

        podio.authenticateWithApp(appId, appToken, (err) => {

          if (err) throw new Error(err);

          podio.isAuthenticated().then(() => {
              // Ready to make API calls in here...
              var requestData = {fields: json};
              
              podio.request('PUT', `/item/${this.state.itemId}`, requestData).then((responseData) => {
                // response, if available
                  if(responseData.item_id !== null){
                    this.setState(prevState => ({
                        showForm: !prevState.showForm,
                        showMsg: !prevState.showMsg
                    }));
                  }
                });         
          }).catch(err => console.log(err));
        });
    }

    render() {
        const preQsub = (
            <div>
            <h2 className="text-center textBlack">Get Pre-Qualified</h2>
            <p className="text-center textBlack">Get qualified for your new home today!</p>
            <Form onSubmit={this.handleSubmit}>
                <FormGroup className="mt-3 mb-3">
                    <Label for="moveInDate">Expected Move-In Date?*</Label>
                    <Input type="date" id="moveInDate" name="moveInDate" Required/>
                </FormGroup>
                <FormGroup className="mt-3 mb-3">
                    <Label for="occupants">How Many Occupants Will Live In The Unit?*</Label>
                    <Input type="text" id="occupants" name="occupants" required/>
                </FormGroup>
                <FormGroup className="mt-3 mb-3">
                    <Label for="grossIncome">Gross Monthly Income?*</Label>
                    <Input type="number" id="grossIncome" name="grossIncome" required/>
                </FormGroup>
                <FormGroup tag="fieldset" className="mt-3 mb-3 pets">
                    <Label for="pets">Do You Have Or Plan To Have Pets?*</Label>
                            <FormGroup check row className="justify-content-center">
                                <Col xs="2">
                                <Label check>Yes</Label>
                                </Col>
                                <Col xs="2">
                                <Input className="petYes" type="radio" name="pets" onChange={this.showInput}/>
                            </Col>
                            <Col xs="2">
                                <Label check>No</Label>
                            </Col>
                            <Col xs="2">
                                <Input className="petNo" type="radio" name="pets" onChange={this.showInput}/>
                            </Col>
                            </FormGroup>
                </FormGroup>
                <FormGroup className="mt-3 mb-3 pet-type">
                    <Label for="pet-type">What Breed, Size, & Weight?*</Label>
                    <Input type="text" id="pet-type" name="pet-type" petReq />
                </FormGroup>
                <FormGroup tag="fieldset" className="mt-3 mb-3 pets">
                    <Label for="eviction">Have You Ever Had An Eviction/Foreclosure?*</Label>
                            <FormGroup check row className="justify-content-center">
                                <Col xs="2">
                                <Label check>Yes</Label>
                                </Col>
                                <Col xs="2">
                                <Input className="evictionYes" type="radio" name="eviction" onChange={this.showInput}/>
                            </Col>
                            <Col xs="2">
                                <Label check>No</Label>
                            </Col>
                            <Col xs="2">
                                <Input className="evictionNo" type="radio" name="eviction" onChange={this.showInput}/>
                            </Col>
                            </FormGroup>
                </FormGroup>
                <FormGroup className="mt-3 mb-3 evictionYear">
                    <Label for="evictionYear">What Year & Why?*</Label>
                    <Input type="text" id="evictionYear" name="evictionYear" />
                </FormGroup>
                <FormGroup tag="fieldset" className="mt-3 mb-3 pets">
                    <Label for="bankruptcy">Have You Ever Had A Bankruptcy?*</Label>
                            <FormGroup check row className="justify-content-center">
                                <Col xs="2">
                                <Label check>Yes</Label>
                                </Col>
                                <Col xs="2">
                                <Input className="bankruptcyYes" type="radio" name="bankruptcy" onChange={this.showInput}/>
                            </Col>
                            <Col xs="2">
                                <Label check>No</Label>
                            </Col>
                            <Col xs="2">
                                <Input className="bankruptcyNo" type="radio" name="bankruptcy" onChange={this.showInput}/>
                            </Col>
                            </FormGroup>
                </FormGroup>
                <FormGroup className="mt-3 mb-3 bankruptcyYear">
                    <Label for="bankruptcyYear">What Year?*</Label>
                    <Input type="text" id="bankruptcyYear" name="bankruptcyYear" />
                </FormGroup>
                <FormGroup tag="fieldset" className="mt-3 mb-3 pets">
                    <Label for="felony">Have You Ever Been Convicted Of A Felony?*</Label>
                            <FormGroup check row className="justify-content-center">
                                <Col xs="2">
                                <Label check>Yes</Label>
                                </Col>
                                <Col xs="2">
                                <Input className="felonyYes" type="radio" name="felony" onChange={this.showInput}/>
                            </Col>
                            <Col xs="2">
                                <Label check>No</Label>
                            </Col>
                            <Col xs="2">
                                <Input className="felonyNo" type="radio" name="felony" onChange={this.showInput}/>
                            </Col>
                            </FormGroup>
                </FormGroup>
                <FormGroup className="mt-3 mb-3 felonyExp">
                    <Label for="felonyExp">Please Explain:*</Label>
                    <Input type="text" id="felonyExp" name="felonyExp" />
                </FormGroup>
                <Row className="justify-content-center">
                    <Button className="subButton mt-3" size="md" color="primary">Get Pre-Qualified</Button>
                </Row>
            </Form>
            </div>
        ); 

        const subMsg = (
            <Row className="justify-content-center">
                <Col className='subMsg'>
                <h4 className="centerText"><span>Thanks For Pre-Qualifying!</span></h4>
                <br/>
                <p>You are one step closer to getting into you new home! A member
                    of our team will reach our shortly.
                </p>
                <br/>
                <p className="centerText">Have a great day!</p>
                </Col>
            </Row>
        );

        return (
            <Container className="hAuto mForm">
                <Row className="hAuto justify-content-center">
                    <Col className="jForm align-self-center">
                        {this.state.showForm ? preQsub : ''}
                        {this.state.showMsg ? subMsg : ''}
                    </Col>
                </Row>
            </Container>
        )
    };
}

export default PreQForm;